// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("C:\\Users\\ashle\\Documents\\ashley-portfolio\\src\\templates\\blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\ashle\\Documents\\ashley-portfolio\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\ashle\\Documents\\ashley-portfolio\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\ashle\\Documents\\ashley-portfolio\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("C:\\Users\\ashle\\Documents\\ashley-portfolio\\src\\pages\\portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

